.gradientText {
	background-image: linear-gradient(81.27deg, #ae5ded 6.66%, #46c8d9 93.34%);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text; /* For Safari compatibility */
	-webkit-text-fill-color: transparent; /* Removes default text color */
}

@keyframes spinAnimation {
	0% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes fadeEffect {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes downloadArrow {
	0% {
		margin-top: -7px;
		opacity: 1;
	}
	0.001% {
		margin-top: -15px;
		opacity: 0;
	}
	50% {
		margin-bottom: -15px;
		opacity: 1;
	}
	100% {
		margin-top: 0;
		opacity: 0;
	}
}

@keyframes whatsNewIconOne {
	0% {
		transform: translate(1px, -1.5px) scale(1);
	}
	50% {
		transform: translate(-0.2px, 1px) scale(1.01);
	}
	100% {
		transform: translate(0, 0) scale(1);
	}
}

@keyframes whatsNewIconTwo {
	0% {
		transform: translate(0, 0) scale(1);
	}
	50% {
		transform: translate(1px, -1px) scale(1.01);
	}
	100% {
		transform: translate(0, 0) scale(1);
	}
}

@keyframes whatsNewIconThree {
	0% {
	  transform: translate(0, 0) scale(1);
	}
	50% {
	  transform: translate(0.5px, -2px) scale(1.05);
	}
	100% {
	  transform: translate(0, 0) scale(1);
	}
}

@font-face {
	font-family: 'IdealSans';
	src:
		url('/public/fonts/idealsans-book-pro-webfont.woff2') format('woff2'),
		url('/public/fonts/idealsans-book-pro-webfont.woff') format('woff');
	font-weight: 400; /* Book */
	font-style: normal;
}

@font-face {
	font-family: 'IdealSans';
	src:
		url('/public/fonts/idealsans-bookitalic-pro-webfont.woff2') format('woff2'),
		url('/public/fonts/idealsans-bookitalic-pro-webfont.woff') format('woff');
	font-weight: 400; /* Book Italic */
	font-style: italic;
}

@font-face {
	font-family: 'IdealSans';
	src:
		url('/public/fonts/idealsans-medium-pro-webfont.woff2') format('woff2'),
		url('/public/fonts/idealsans-medium-pro-webfont.woff') format('woff');
	font-weight: 500; /* Medium */
	font-style: normal;
}

@font-face {
	font-family: 'IdealSans';
	src:
		url('/public/fonts/idealsans-mediumitalic-pro-webfont.woff2') format('woff2'),
		url('/public/fonts/idealsans-mediumitalic-pro-webfont.woff') format('woff');
	font-weight: 500; /* Medium Italic */
	font-style: italic;
}

/* Roboto Font Faces */
@font-face {
	font-family: 'Roboto';
	src:
		url('/public/fonts/roboto-regular-webfont.woff2') format('woff2'),
		url('/public/fonts/roboto-regular-webfont.woff') format('woff');
	font-weight: 400; /* Regular */
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src:
		url('/public/fonts/roboto-medium-webfont.woff2') format('woff2'),
		url('/public/fonts/roboto-medium-webfont.woff') format('woff');
	font-weight: 500; /* Medium */
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src:
		url('/public/fonts/roboto-bold-webfont.woff2') format('woff2'),
		url('/public/fonts/roboto-bold-webfont.woff') format('woff');
	font-weight: 700; /* Bold */
	font-style: normal;
}

.codeblock .keyword {
	color: #ff79c6; /* Pink */
}

.codeblock .string {
	color: #f1fa8c; /* Yellow */
}

.codeblock .comment {
	color: #6272a4; /* Gray */
	font-style: italic;
}

.codeblock .number {
	color: #bd93f9; /* Purple */
}

.codeblock .function {
	color: #50fa7b; /* Green */
}

body {
	overflow-x: hidden !important;
}